<template>
  <div class="bg-white l-wrapper">
    <div class="q-pa-md">
      <q-input
        outlined
        label="Search"
        v-model="pagination.search"
        @update:model-value="
          pagination.currentPage = 1;
          getTest();
        "
      ></q-input>
      <div class="row justify-evenly" v-can="['PURCHASING', 'MANAGER', 'RND']">
        <q-radio
          v-model="pagination.filter"
          @update:model-value="
            pagination.currentPage = 1;
            getTest();
          "
          val="ALL"
          label="ALL"
        ></q-radio>
        <q-radio
          v-model="pagination.filter"
          @update:model-value="
            pagination.currentPage = 1;
            getTest();
          "
          val="REQUEST"
          label="REQUEST"
        ></q-radio>
        <q-radio
          v-model="pagination.filter"
          @update:model-value="
            pagination.currentPage = 1;
            getTest();
          "
          val="PROCESS"
          label="PROCESS"
        ></q-radio>
        <q-radio
          v-model="pagination.filter"
          @update:model-value="
            pagination.currentPage = 1;
            getTest();
          "
          val="DONE"
          label="DONE"
        ></q-radio>
      </div>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-list separator>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="item in testList"
          :key="item.id"
          :to="`/app/test/chat/${item.id}`"
          v-ripple
        >
          <q-item-section top>
            <q-item-label lines="1" class="text-capitalize"
              >{{ item.item_name }}
            </q-item-label>
            <q-item-label caption>
              {{ item.supplier_name }}
            </q-item-label>
            <q-item-label caption lines="1">
              {{ moment(item.created).format("DD MMM") }} -
              {{ moment(item.deadline).format("DD MMM YYYY") }}
            </q-item-label>
          </q-item-section>
          <q-item-section top side>
            <q-item-label lines="1">
              {{ item.request_status }}
              <q-icon
                class="text-blue-grey-3"
                name="biotech"
                style="font-size: 1.6em;"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div class="q-py-sm flex flex-center">
      <q-pagination
        v-model="pagination.currentPage"
        :max="pagination.totalPage"
        input
        input-class="text-orange-10"
        @update:model-value="changePage"
      />
    </div>

    <q-btn
      v-can="['PURCHASING']"
      class="full-width no-border-radius"
      color="primary"
      label="NEW TESTING"
      :to="`/app/test/form/-1`"
    ></q-btn>

    <q-dialog position="bottom" v-model="modalFilter" persistent>
      <q-card>
        <q-card-section>
          <a class="text-h6">Filter</a>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <span>Something</span>
            <q-input outlined></q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="primary" label="Batal" v-close-popup></q-btn>
          <q-btn unelevated color="primary" label="Apply"></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { computed, ref, reactive, inject } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    let searchTerm = ref("");
    const $http = inject("$http");
    const $q = useQuasar();

    let testList = ref([]);
    let pagination = reactive({
      search: "",
      filter: "ALL",
      currentPage: 1,
      totalPage: 1,
      rowPerPage: 10,
    });
    let changePage = async () => {
      await getTest();
    };
    let curUser = ref({});

    let getTest = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      pagination.offset = pagination.rowPerPage * (pagination.currentPage - 1);
      pagination.limit = pagination.rowPerPage;
      pagination.id = curUser.value.id;
      pagination.role = "";
      if (curUser.value.is_purchasing == 1) {
        pagination.role = "purchasing";
      } else {
        pagination.role = "all";
      }
      let resp = await $http.post("/test/list", pagination);
      testList.value = resp.data.rows;

      if (testList.value.length > 0) {
        pagination.totalPage = Math.ceil(
          resp.data.total_row / pagination.rowPerPage
        );
      }
      router.replace({
        path: `/app/test/list/${pagination.filter}/${pagination.currentPage}/${pagination.search}`,
      });
      $q.loading.hide();
    };

    let checkParams = () => {
      if (route.params.status) {
        pagination.filter = route.params.status;
      }
      if (route.params.page) {
        pagination.currentPage = parseInt(route.params.page);
      }
      if (route.params.search) {
        pagination.search = route.params.search;
      }
    };

    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      checkParams();
      await getTest();
    });

    return {
      modalFilter: ref(false),
      searchTerm,
      router,
      getTest,
      pagination,
      changePage,
      testList,
      route,
      moment,
    };
  },
};
</script>
